import { AuthPaths, useAuth } from "@wojo/auth";
import { useI18n } from "@wojo/localization";
import { getCleanStoryblokLink } from "@wojo/storyblok";
import { Button, Text } from "@wojo/ui";
import { MobileMegamenu } from "components/megamenu";
import { MobileMyAccountMenu } from "../my-account-menu/MobileMyAccountMenu";
import { AppBanner } from "./AppBanner";
import { WebConfigContentProps } from "./Header";
import styles from "./MobileNav.module.scss";

export const MobileNav: React.FC<WebConfigContentProps> = ({
    webConfigContent,
}) => {
    const headerContent = webConfigContent.header;
    const { isLoggedIn, setView } = useAuth();
    const { header } = useI18n("header");
    const loginRegister = header.authentication.loginRegister.l();
    return (
        <>
            <ul className={styles["nav-list"]}>
                <MobileMegamenu webConfigContent={webConfigContent} />
                {headerContent.topLinks?.map((topLink) => (
                    <Text
                        emphasis="2"
                        tag="li"
                        key={topLink.text}
                        className={styles["list-item"]}
                    >
                        <a
                            className={styles.link}
                            href={getCleanStoryblokLink(topLink.link).href}
                        >
                            {topLink.text}
                        </a>
                    </Text>
                ))}
                {isLoggedIn ? (
                    <MobileMyAccountMenu />
                ) : (
                    <Text emphasis="2" tag="li" className={styles["list-item"]}>
                        <Button
                            className={styles["link--login"]}
                            variant="quiet"
                            onClick={() => {
                                setView?.({
                                    view: AuthPaths.VerifyEmail,
                                });
                            }}
                        >
                            {loginRegister}
                        </Button>
                    </Text>
                )}
            </ul>
            <div className={styles["banner-section"]}>
                <AppBanner webConfigContent={webConfigContent} />
            </div>
        </>
    );
};
