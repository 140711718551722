import { AuthPaths, useAuth } from "@wojo/auth";
import cn from "clsx";
import { FiHeart } from "react-icons/fi";
import styles from "./PhoneAndWishlistButton.module.scss";
import { UtilityButton } from "./UtilityButton";
import { UtilityLink } from "./UtilityLink";

export type WishlistProps = {
    isModalVariant?: boolean;
};

export const WishlistButton: React.FC<WishlistProps> = ({
    isModalVariant = false,
}) => {
    const { isLoggedIn, setView } = useAuth();
    const wishlistUrl =
        process.env.NEXT_PUBLIC_UNRELEASED_FEATURE_SAVED_QUOTES === "true"
            ? "/myaccount/saved#wishlist"
            : "/myaccount/saved";

    return isLoggedIn ? (
        <UtilityLink href={wishlistUrl} isModalVariant={isModalVariant}>
            <span className={styles.wrapper}>
                <span
                    className={cn(styles["text"], {
                        [styles["text--modal"]]: isModalVariant,
                    })}
                >
                    Wishlist
                </span>
                <FiHeart
                    className={cn(styles["icon"], {
                        [styles["icon--modal"]]: isModalVariant,
                    })}
                    aria-hidden
                />
            </span>
        </UtilityLink>
    ) : (
        <>
            <UtilityButton
                className={cn(styles["button"], {
                    [styles["button--modal"]]: isModalVariant,
                })}
                onClick={() => {
                    setView?.({
                        view: AuthPaths.VerifyEmailWishlist,
                    });
                }}
            >
                <span
                    className={cn(styles["text"], {
                        [styles["text--modal"]]: isModalVariant,
                    })}
                >
                    Wishlist
                </span>
                <FiHeart
                    className={cn(styles["icon"], {
                        [styles["icon--modal"]]: isModalVariant,
                    })}
                    aria-hidden
                />
            </UtilityButton>
        </>
    );
};
