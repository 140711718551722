import { gtmSendEvent } from "@wojo/analytics";
import { useAuth } from "@wojo/auth";
import { useI18n } from "@wojo/localization";
import { Avatar, Button, Collapsible, Text, transformAsset } from "@wojo/ui";
import { ZendeskHelpCenterLink } from "@wojo/web-components";
import cn from "clsx";
import { PropsWithChildren } from "react";
import { FiExternalLink } from "react-icons/fi";
import mobileNavStyles from "../header/MobileNav.module.scss";
import { useAccountMenuData } from "./hooks/use-account-menu-data";
import myAccountStyles from "./MobileMyAccountMenu.module.scss";

export const MobileMyAccountMenu: React.FC = () => {
    const { customer, signout } = useAuth();
    const { accountMenuLabel, accountMenuData } = useAccountMenuData();
    const { header } = useI18n("header");
    const { global } = useI18n("global");
    const signOut = header.authentication.signOut.l();
    const avatarSrc = customer?.profileImageAsset?.location ?? "";

    return (
        <li>
            <Collapsible
                className={myAccountStyles.collapsible}
                expandedClassName={myAccountStyles["collapsible--expanded"]}
                title={
                    <Text.Body
                        emphasis="2"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {accountMenuLabel}
                        <Avatar
                            size={26}
                            altText={`${customer?.firstName} ${customer?.lastName}`.trim()}
                            initials={
                                customer?.firstName
                                    ? `${customer.firstName.charAt(
                                          0,
                                      )}${customer.lastName?.charAt(0)}`
                                    : ""
                            }
                            src={transformAsset(avatarSrc, {
                                size: {
                                    width: 26,
                                    height: 26,
                                },
                            })}
                        />
                    </Text.Body>
                }
                hideBottomBorder
            >
                <ul
                    style={{
                        listStyle: "none",
                        padding: 0,
                    }}
                >
                    {accountMenuData.map((item) => (
                        <MenuItem
                            key={item.title}
                            href={item.href}
                            icon={item.icon}
                            title={item.title}
                        />
                    ))}
                    {process.env.NEXT_PUBLIC_ZENDESK_HELP_CENTER_URL && (
                        <MenuItem>
                            <ZendeskHelpCenterLink
                                adornRight={
                                    <FiExternalLink aria-hidden="true" />
                                }
                                className={myAccountStyles["zendesk-link"]}
                            >
                                {global.accountNavigation.helpCenter.text.l()}
                            </ZendeskHelpCenterLink>
                        </MenuItem>
                    )}
                    <Text
                        tag="li"
                        emphasis="1"
                        className={myAccountStyles["menu-item"]}
                    >
                        <Button
                            onClick={async () => {
                                await signout?.();
                                gtmSendEvent({
                                    event: "track",
                                    ga4Tracking: {
                                        eventCategory: "login",
                                        eventAction: "header register now",
                                        eventLabel: "logout",
                                        ga4_object: "LOGIN",
                                        ga4_action: "LOGGED_OUT",
                                        loginId: "headerPasswordAccount",
                                        maginLink: "no",
                                    },
                                });
                            }}
                            className={cn(
                                mobileNavStyles["link--login"],
                                mobileNavStyles["link--login--sign-out"],
                            )}
                            variant="quiet"
                        >
                            {signOut}
                        </Button>
                    </Text>
                </ul>
            </Collapsible>
        </li>
    );
};

type MenuItemProps = PropsWithChildren<{
    href?: string;
    icon?: React.ReactNode;
    title?: string;
}>;

const MenuItem: React.FC<MenuItemProps> = ({ children, href, icon, title }) => {
    const className = cn(mobileNavStyles.link, {
        [myAccountStyles["title-with-icon"]]: !!icon,
    });

    return (
        <Text tag="li" emphasis="1" className={myAccountStyles["menu-item"]}>
            {children || (
                <a className={className} href={href}>
                    {title}
                    {icon}
                </a>
            )}
        </Text>
    );
};
