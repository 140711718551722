import cn from "clsx";
import { PropsWithChildren } from "react";
import styles from "./UtilityLink.module.scss";

export type UtilityLinkType = {
    href: string;
    isModalVariant?: boolean;
} & React.HTMLAttributes<HTMLAnchorElement>;

export const UtilityLink: React.FC<PropsWithChildren<UtilityLinkType>> = ({
    href,
    isModalVariant = false,
    className,
    children,
    ...utilityProps
}) => {
    return (
        <a
            {...utilityProps}
            className={cn(styles["utility-link"], className, {
                [styles["utility-link--modal"]]: isModalVariant,
            })}
            href={href}
        >
            {children}
        </a>
    );
};
