import { getCleanStoryblokLink } from "@wojo/storyblok";
import { Text } from "@wojo/ui";
import { WebConfigContentProps } from "components/header/Header";
import React from "react";
import navStyles from "../header/MobileNav.module.scss";
import { NavCollapsible } from "./NavCollapsible";

export const MobileMegamenu: React.FC<WebConfigContentProps> = ({
    webConfigContent,
}) => {
    const headerContent = webConfigContent.header;

    if (!headerContent.mainNav) {
        return null;
    }

    return (
        <>
            {headerContent.mainNav.map((navItem) => {
                const hasColumns = !!navItem.columns?.length;
                return !hasColumns ? (
                    <Text.Body
                        emphasis="2"
                        tag="li"
                        key={navItem.title}
                        className={navStyles["list-item"]}
                    >
                        <a
                            href={getCleanStoryblokLink(navItem.link).href}
                            className={navStyles.link}
                        >
                            {navItem.title}
                        </a>
                    </Text.Body>
                ) : (
                    <NavCollapsible navItem={navItem} key={navItem.title} />
                );
            })}
        </>
    );
};
