import { WebConfigContentProps } from "components/header/Header";
import styles from "./DesktopMegamenu.module.scss";
import { NavItem } from "./NavItem";

export const DesktopMegamenu: React.FC<
    WebConfigContentProps & { className?: string }
> = ({ webConfigContent, className }) => {
    const headerContent = webConfigContent.header;

    if (!headerContent.mainNav) {
        return null;
    }

    return (
        <nav aria-label="Site" className={className}>
            <ul className={styles.list}>
                {headerContent.mainNav.map((navItem) => {
                    return <NavItem navItem={navItem} key={navItem.title} />;
                })}
            </ul>
        </nav>
    );
};
