import {
    ButtonAtom,
    getCleanStoryblokLink,
    WojoNavGroup,
} from "@wojo/storyblok";
import { Collapsible, Text } from "@wojo/ui";
import cn from "clsx";
import listStyles from "../header/MobileNav.module.scss";
import styles from "./NavCollapsible.module.scss";

type NavCollapsibleProps = {
    navItem: WojoNavGroup;
};

export const NavCollapsible: React.FC<NavCollapsibleProps> = ({ navItem }) => {
    return (
        <li key={`${navItem.title}_collapsible`}>
            <Collapsible
                title={<Text.Body emphasis="2">{navItem.title}</Text.Body>}
                className={listStyles["list-item"]}
                expandedClassName={styles.expanded}
                hideBottomBorder
                data-ga4-object="NAV_COLLAPSIBLE"
            >
                {navItem.columns?.map((column, indexColumn) => {
                    const linkArray = column.items?.map((item, indexItem) => {
                        return (
                            <Text.Body
                                tag="li"
                                emphasis="1"
                                key={`${item.text}_li_${indexColumn}_${indexItem}`}
                            >
                                <a
                                    href={getCleanStoryblokLink(item.link).href}
                                    className={styles.link}
                                >
                                    {item.text}
                                </a>
                            </Text.Body>
                        );
                    });

                    return column.columnTitle ? (
                        <div
                            className={styles["collapsible-wrapper"]}
                            key={`div_${indexColumn}`}
                        >
                            <Collapsible
                                title={
                                    <Text.Body emphasis="2">
                                        {column.columnTitle}
                                    </Text.Body>
                                }
                                key={`${column.columnTitle}_collapsible_${indexColumn}`}
                                hideBottomBorder
                                className={listStyles["list-item"]}
                            >
                                <ul className={styles["links-wrapper"]}>
                                    {linkArray}
                                </ul>
                            </Collapsible>
                        </div>
                    ) : (
                        <ul
                            key={`ul_${indexColumn}`}
                            className={cn(
                                styles["links-wrapper"],
                                styles["links-wrapper--no-title"],
                            )}
                        >
                            {linkArray}
                        </ul>
                    );
                })}
                {navItem.cta?.at(0) && (
                    <div
                        style={{
                            paddingTop: "var(--g-spacing-md)",
                            borderTop: navItem.columns?.at(0)?.columnTitle
                                ? "var(--g-border-width-xs) solid var(--s-color-separator-standard)"
                                : "",
                        }}
                        key={`${navItem.title}_button`}
                    >
                        <ButtonAtom {...navItem.cta[0]} />
                    </div>
                )}
            </Collapsible>
        </li>
    );
};
