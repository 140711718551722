import { useI18n } from "@wojo/localization";
import { Text } from "@wojo/ui";
import { PropsWithChildren } from "react";
import styles from "./SiteSearch.module.scss";
import { SiteSearchCard } from "./SiteSearchCard";
import { useSiteSearch } from "./SiteSearchContextProvider";

export const SiteSearchSectionTours: React.FC<
    PropsWithChildren<{ isMobile?: boolean }>
> = ({ isMobile }) => {
    const { tourPageData, searchValue, isShowingDefaultResults, pagesData } =
        useSiteSearch();

    const headerLocale = useI18n("header");

    return (
        !!searchValue && (
            <section
                className={styles.tours}
                style={{
                    // We don't need padding on desktop site search results layout. This is only required for mobile for this one edge case.
                    paddingTop:
                        isMobile && !pagesData.length
                            ? "var(--g-spacing-xl)"
                            : undefined,
                }}
            >
                <Text.Detail variant="2">
                    {headerLocale.header.siteSearch.featuredTours.l()}
                </Text.Detail>
                {isShowingDefaultResults && !!searchValue && (
                    <Text>
                        {headerLocale.header.siteSearch.noResults.l({
                            searchTerm: searchValue,
                        })}
                    </Text>
                )}
                {tourPageData.map((item) => (
                    <SiteSearchCard
                        key={item.objectID}
                        url={item.url}
                        nbDays={item.nbDays}
                        nbDaysWithExtension={item.nbDaysWithExtension}
                        extensionName={item.extensionName}
                        name={item.name}
                        tourCode={item.tourCode}
                        cardImage={
                            item.cardImage
                                ? {
                                      alt: item.cardImage.alt,
                                      name: item.cardImage.name,
                                      filename: item.cardImage.filename,
                                      focus: item.cardImage.focus,
                                  }
                                : undefined
                        }
                    />
                ))}
            </section>
        )
    );
};
