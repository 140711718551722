import cn from "clsx";
import { forwardRef, PropsWithChildren } from "react";
import styles from "./UtilityLink.module.scss";

export type UtilityNavButtonType =
    {} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const UtilityButton = forwardRef<
    HTMLButtonElement,
    PropsWithChildren<UtilityNavButtonType>
>(({ className, children, ...utilityProps }, ref) => {
    return (
        <button
            {...utilityProps}
            ref={ref}
            type="button"
            className={cn(styles["utility-link"], className)}
        >
            {children}
        </button>
    );
});

UtilityButton.displayName = "UtilityButton";

export { UtilityButton };
