import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import {
    algoliaClient,
    BlogPostHit,
    getAlgoliaIndex,
    PageHit,
    TourPageHit,
} from "@wojo/services";
import { AlgoliaIndexes } from "@wojo/services/src/algolia-search/get-algolia-index";
import algoliasearchHelper from "algoliasearch-helper";
import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from "react";

const SiteSearchContext = createContext<{
    /**function executed on user typing */
    indexSearch: (value?: string) => Promise<void>;
    /** function executed on Enter or on lense icon click */
    onSearch: (value: string) => Promise<void>;
    tourPageData: TourPageHit[];
    pagesData: PageHit[];
    blogData: BlogPostHit[];
    isOpen: boolean;
    /** sets the modal/dropdown open state */
    setIsOpen: (value: boolean) => void;
    /** search input value */
    searchValue?: string;
    /** Flag designating if default (Featured) results are being shown */
    isShowingDefaultResults?: boolean;
    isLoading?: boolean;
}>({
    indexSearch: async () => {},
    onSearch: async () => {},
    tourPageData: [],
    pagesData: [],
    blogData: [],
    isOpen: false,
    setIsOpen: () => {},
});

// Custom hook to access the context values
export const useSiteSearch = () => useContext(SiteSearchContext);

// Context provider component
export const SiteSearchContextProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isShowingDefaultResults, setIsShowingDefaultResults] =
        useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [tourPageData, setTourPageData] = useState<TourPageHit[]>([]);
    const [blogData, setBlogData] = useState<BlogPostHit[]>([]);
    const [pagesData, setPagesData] = useState<PageHit[]>([]);
    const businessCode = process.env.NEXT_PUBLIC_BUSINESS_CODE ?? "";
    const headerLocale = useI18n("header");

    const tourPagesSearchHelper = algoliasearchHelper(
        algoliaClient,
        getAlgoliaIndex(AlgoliaIndexes.TOURPAGES),
        {
            facets: ["businessCode"],
            disjunctiveFacets: [],
            hitsPerPage: 3,
            page: 0,
        },
    );
    const pagesSearchHelper = algoliasearchHelper(
        algoliaClient,
        getAlgoliaIndex(AlgoliaIndexes.PAGES),
        {
            facets: ["businessCode"],
            disjunctiveFacets: [],
            hitsPerPage: 5,
            page: 0,
        },
    );
    const blogSearchHelper = algoliasearchHelper(
        algoliaClient,
        getAlgoliaIndex(AlgoliaIndexes.BLOGPOSTS),
        {
            facets: ["businessCode"],
            disjunctiveFacets: [],
            hitsPerPage: 4,
            page: 0,
        },
    );

    tourPagesSearchHelper.addFacetRefinement("businessCode", businessCode);
    pagesSearchHelper.addFacetRefinement("businessCode", businessCode);
    blogSearchHelper.addFacetRefinement("businessCode", businessCode);

    const indexSearch = async (value?: string) => {
        setIsLoading(true);
        if (value) {
            tourPagesSearchHelper.setQuery(value);
            pagesSearchHelper.setQuery(value);
            blogSearchHelper.setQuery(value);

            const [pagesResponse, blogResponse, tourPagesResponse] =
                await Promise.all([
                    pagesSearchHelper.searchOnce({}),
                    blogSearchHelper.searchOnce({}),
                    tourPagesSearchHelper.searchOnce({
                        attributesToHighlight: ["siteSearchKeyword"],
                    }),
                ]);
            // if no results, show default featured results.
            if (tourPagesResponse.content.hits.length === 0) {
                setIsShowingDefaultResults(true);
                tourPagesSearchHelper.setQuery("");
                const suggestedResponse =
                    await tourPagesSearchHelper.searchOnce({});
                setTourPageData(suggestedResponse.content.hits);
            } else {
                // set the found tour page search results
                setTourPageData(tourPagesResponse.content.hits);
                setIsShowingDefaultResults(false);
            }

            setPagesData(pagesResponse.content.hits);
            setBlogData(blogResponse.content.hits);

            setIsOpen(true);
            gtmSendEvent({
                event: "track",
                ga4Tracking: {
                    ga4_object: "SEARCH_BAR",
                    ga4_action: "SEARCHED",
                    searchTerm: value,
                    isInstant: "false",
                },
            });
        } else {
            // clear search results if search field is empty
            setTourPageData([]);
            setPagesData([]);
            setBlogData([]);
        }
        setSearchValue(value ?? "");
        setIsLoading(false);
    };
    const onSearch = async (value: string) => {
        if (!value) {
            return;
        }
        // set the search query and get the search results on Enter or on lense icon click
        setIsLoading(true);
        pagesSearchHelper.setQuery(value);
        const pagesData = (await pagesSearchHelper.searchOnce({})).content.hits;

        let redirectUrl = "";

        // redirect to first suggested result
        if (pagesData.length > 0) {
            redirectUrl = pagesData[0].fullSlug;
        } else {
            if (!!value) {
                // try to fallback to the tourPages
                tourPagesSearchHelper.setQuery(value);
                const tourPagesData = (
                    await tourPagesSearchHelper.searchOnce({})
                ).content.hits;
                if (tourPagesData.length > 0) {
                    redirectUrl = tourPagesData[0].url;
                } else {
                    // give up and navigate to oops no results page.
                    redirectUrl =
                        headerLocale.header.siteSearch.noResultsUrl.l();
                }
            }
        }
        if (redirectUrl) {
            gtmSendEvent({
                event: "track",
                ga4Tracking: {
                    ga4_object: "SEARCH_BAR",
                    ga4_action: "SEARCHED",
                    searchTerm: value,
                    isInstant: "true",
                    destinationURL: redirectUrl,
                },
            });

            window.location.href = redirectUrl;
        }
    };
    const onSetIsOpen = (value: boolean) => {
        setIsOpen(value);
        if (!value) {
            setTourPageData([]);
        }
    };

    // clear the search results when the modal/dropdown is closed
    useEffect(() => {
        if (!isOpen) {
            setTourPageData([]);
            setBlogData([]);
            setSearchValue("");
        }
    }, [isOpen]);
    return (
        <SiteSearchContext.Provider
            value={{
                indexSearch,
                onSearch,
                tourPageData,
                isOpen,
                setIsOpen: onSetIsOpen,
                searchValue,
                isShowingDefaultResults,
                pagesData,
                blogData,
                isLoading,
            }}
        >
            {children}
        </SiteSearchContext.Provider>
    );
};
