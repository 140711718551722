import { PhoneLink } from "./PhoneLink";
import { usePhoneNumber } from "client/hooks";

export const MobileHeaderPhone: React.FC = () => {
    const { phoneNumber, formattedPhoneNumber } = usePhoneNumber();

    return (
        <PhoneLink
            phoneNumber={phoneNumber}
            formattedPhone={formattedPhoneNumber}
        />
    );
};
