import { useMediaQuery } from "@mantine/hooks";
import { LoadingSpinner, Modal, ModalBody, SearchField } from "@wojo/ui";
import { useEffect, useRef } from "react";
import { useSiteSearch } from "./SiteSearchContextProvider";
import { SiteSearchSectionBlog } from "./SiteSearchSectionBlog";
import { SiteSearchSectionPages } from "./SiteSearchSectionPages";
import { SiteSearchSectionTours } from "./SiteSearchSectionTours";

type SiteSearchMobileProps = { placeholder?: string };

export const SiteSearchMobile: React.FC<SiteSearchMobileProps> = ({
    placeholder,
}) => {
    const { isOpen, setIsOpen, indexSearch, onSearch, isLoading } =
        useSiteSearch();
    const ref = useRef<HTMLInputElement | null>(null);
    const breakpointDesktop = "(max-width: 992px)";
    const isMobile = useMediaQuery(breakpointDesktop, false);
    const fakeSearchBoxRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (!isMobile && isOpen) {
            setIsOpen(false);
        }
    }, [isMobile, isOpen, setIsOpen]);

    useEffect(() => {
        ref.current?.focus();
    });

    return (
        <>
            {/* Fake search field */}
            <SearchField
                readOnly
                placeholder={placeholder}
                variant="quiet"
                autoFocus={false}
                onClick={() => setIsOpen(true)}
                onSearch={async () => setIsOpen(true)}
                ref={fakeSearchBoxRef}
            />

            <Modal
                variant="fullscreen"
                isOpen={isOpen}
                accessibleTitle="Search site"
                disableModalTranslate
                onCloseRequested={() => {
                    fakeSearchBoxRef.current?.focus();
                    setIsOpen(false);
                }}
            >
                <ModalBody>
                    {/* Real search field */}
                    <SearchField
                        onSearch={onSearch}
                        onTypingStopped={indexSearch}
                        placeholder={placeholder}
                        variant="quiet"
                        onClear={() => setIsOpen(false)}
                        ref={ref}
                        loading={isLoading}
                    />
                    {isLoading ? (
                        <div
                            style={{
                                display: "grid",
                                paddingTop: "var(--g-spacing-md",
                                justifyContent: "center",
                            }}
                        >
                            <LoadingSpinner accessibleText="Loading search results" />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "grid",
                                rowGap: "var(--g-spacing-xl)",
                            }}
                        >
                            <SiteSearchSectionPages />
                            <SiteSearchSectionTours isMobile />
                            <SiteSearchSectionBlog />
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};
