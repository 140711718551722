import {
    FloatingFocusManager,
    FloatingPortal,
    useDismiss,
    useFloating,
    useInteractions,
    useTransitionStyles,
} from "@floating-ui/react";
import { useMediaQuery } from "@mantine/hooks";
import { gtmSendEvent } from "@wojo/analytics";
import {
    ButtonAtom,
    getCleanStoryblokLink,
    WojoNavGroup,
} from "@wojo/storyblok";
import { Button, Container, Text } from "@wojo/ui";
import cn from "clsx";
import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import dropdownStyles from "./NavDropdown.module.scss";
import itemStyles from "./NavItem.module.scss";

type NavDropdownProps = {
    navItem: WojoNavGroup;
};

export const NavDropdown: React.FC<NavDropdownProps> = ({ navItem }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { context, refs } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useDismiss(context, { outsidePressEvent: "click" }),
    ]);
    const { styles } = useTransitionStyles(context, { open: { opacity: 1 } });

    const breakpointDesktop = "(min-width: 992px)";
    const isDesktop = useMediaQuery(breakpointDesktop, false);

    useEffect(() => {
        if (!isDesktop && isOpen) {
            setIsOpen(false);
        }
    }, [isDesktop, isOpen]);

    return (
        <li>
            <Button
                ref={refs.setReference}
                variant="quiet"
                className={itemStyles.item}
                {...getReferenceProps({
                    onClick: () => {
                        setIsOpen(!isOpen);
                        gtmSendEvent({
                            event: "track",
                            ga4Tracking: {
                                ga4_object: "NAV_BAR",
                                ga4_action: "CLICKED",
                                clickText: navItem.title ?? "",
                                sectionTier: "Tier 1",
                                destinationUrl: "",
                            },
                        });
                    },
                    "aria-expanded": `${isOpen}`,
                })}
            >
                <Text.Body tag="span" className={itemStyles.title}>
                    {navItem.title}
                    <div className={itemStyles.icon}>
                        <FiChevronDown
                            size={16}
                            aria-hidden="true"
                            className={cn({
                                [dropdownStyles["expanded"]]: isOpen,
                                [dropdownStyles["collapsed"]]: !isOpen,
                            })}
                        />
                    </div>
                </Text.Body>
            </Button>

            {isOpen && (
                <FloatingPortal id="header-container">
                    <FloatingFocusManager context={context} modal={false}>
                        <div
                            ref={refs.setFloating}
                            className={dropdownStyles.dropdown}
                            style={{ ...styles }}
                            {...getFloatingProps()}
                        >
                            <Container
                                contentsClassName={
                                    dropdownStyles["dropdown-content"]
                                }
                            >
                                {navItem.columns?.map((column, index) => {
                                    return (
                                        <div
                                            key={`${navItem.title}_${index}`}
                                            className={
                                                dropdownStyles[
                                                    "dropdown-column"
                                                ]
                                            }
                                            style={
                                                {
                                                    "--grid-columns": `${
                                                        (navItem.columns
                                                            ?.length ?? 0) > 4
                                                            ? "0 0 calc((100% - (4 * var(--g-spacing-xl))) / 5)"
                                                            : "0 0 calc((100% - (3 * var(--g-spacing-xl))) / 4)"
                                                    }`,
                                                } as React.CSSProperties
                                            }
                                        >
                                            {column.columnTitle && (
                                                <Text.Body
                                                    emphasis="2"
                                                    id={`Heading_${index}`}
                                                    className={
                                                        dropdownStyles[
                                                            "column-heading"
                                                        ]
                                                    }
                                                >
                                                    {column.columnTitle}
                                                </Text.Body>
                                            )}
                                            <ul
                                                aria-labelledby={
                                                    column.columnTitle
                                                        ? `Heading_${index}`
                                                        : undefined
                                                }
                                                className={
                                                    dropdownStyles[
                                                        "column-list"
                                                    ]
                                                }
                                            >
                                                {column.items?.map((item) => {
                                                    const { href } =
                                                        getCleanStoryblokLink(
                                                            item.link,
                                                        );

                                                    return (
                                                        <li
                                                            key={`${column.columnTitle}_${item.text}`}
                                                        >
                                                            <Text.Body
                                                                tag="a"
                                                                href={href}
                                                                onClick={() =>
                                                                    gtmSendEvent(
                                                                        {
                                                                            event: "track",
                                                                            ga4Tracking:
                                                                                {
                                                                                    ga4_object:
                                                                                        "NAV_BAR",
                                                                                    ga4_action:
                                                                                        "CLICKED",
                                                                                    clickText:
                                                                                        item.text ??
                                                                                        "",
                                                                                    sectionTier:
                                                                                        "Tier 2",
                                                                                    destinationUrl:
                                                                                        href,
                                                                                },
                                                                        },
                                                                    )
                                                                }
                                                                className={
                                                                    dropdownStyles.link
                                                                }
                                                            >
                                                                {item.text}
                                                            </Text.Body>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    );
                                })}

                                {navItem.cta?.at(0) && (
                                    <div
                                        className={
                                            dropdownStyles["dropdown-button"]
                                        }
                                    >
                                        <ButtonAtom
                                            {...navItem.cta[0]}
                                            onClick={() =>
                                                gtmSendEvent({
                                                    event: "track",
                                                    ga4Tracking: {
                                                        ga4_object: "NAV_BAR",
                                                        ga4_action: "CLICKED",
                                                        clickText:
                                                            navItem.cta?.at(0)
                                                                ?.text ?? "",
                                                        sectionTier: "Tier 3",
                                                        destinationUrl:
                                                            navItem.cta?.at(0)
                                                                ?.link
                                                                ?.cached_url ??
                                                            "",
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                )}
                            </Container>
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </li>
    );
};
