"use client";

import { ImageGraphicAtom } from "@wojo/storyblok";
import { Button, Modal, ModalBody } from "@wojo/ui";
import cn from "clsx";
import { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { WebConfigContentProps } from "./Header";
import styles from "./MobileMenu.module.scss";
import { MobileNav } from "./MobileNav";
import { PhoneLink } from "./PhoneLink";
import { WishlistButton } from "./WishlistButton";
import { usePhoneNumber } from "client/hooks";

export const MobileMenu: React.FC<WebConfigContentProps> = ({
    webConfigContent,
}) => {
    const { phoneNumber, formattedPhoneNumber } = usePhoneNumber();

    const headerContent = webConfigContent.header;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button
                adornRight={<FiMenu aria-hidden />}
                className={styles["menu-button"]}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                variant="quiet"
                aria-expanded={isOpen}
                data-testid="mobile-menu-button"
            >
                Menu
            </Button>
            {isOpen && (
                <Modal
                    accessibleTitle="Site navigation"
                    variant="fullscreen"
                    hideDefaultHeader
                    isOpen={isOpen}
                    onCloseRequested={() => {
                        setIsOpen(!isOpen);
                    }}
                    disableModalTranslate
                    contentClassName={styles.modal}
                >
                    <header className={styles["modal-header"]}>
                        <div className={styles["modal-header-content"]}>
                            {!!headerContent.logo[0] && (
                                <a
                                    href={"/"}
                                    className={styles["modal-logo-link"]}
                                >
                                    <ImageGraphicAtom
                                        className={styles["modal-logo"]}
                                        {...headerContent.logo[0]}
                                    />
                                </a>
                            )}
                            <div className={styles["modal-nav"]}>
                                {headerContent.showPhoneOrWishlist ===
                                    "phone" && (
                                    <PhoneLink
                                        phoneNumber={phoneNumber}
                                        formattedPhone={formattedPhoneNumber}
                                        isModalVariant
                                    />
                                )}
                                {headerContent.showPhoneOrWishlist ===
                                    "wishlist" && (
                                    <WishlistButton isModalVariant />
                                )}
                                <Button
                                    adornRight={<FiX aria-hidden />}
                                    className={cn(
                                        styles["menu-button"],
                                        styles["menu-button--modal"],
                                    )}
                                    variant="quiet"
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                    }}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </header>
                    <ModalBody className={styles["modal-body"]}>
                        <MobileNav webConfigContent={webConfigContent} />
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};
