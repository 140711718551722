import { gtmSendEvent } from "@wojo/analytics";
import { AssetStoryblok, StoryblokImage } from "@wojo/storyblok";
import { Card, Text } from "@wojo/ui";
import pluralize from "pluralize";
import React from "react";
import styles from "./SiteSearchCard.module.scss";
import { useSiteSearch } from "./SiteSearchContextProvider";

export type SiteSearchCardProps = {
    /**
     * The card image to display.
     */
    cardImage?: AssetStoryblok;

    nbDays: number;
    /**
     * The name of the tour.
     */
    name: string;
    /**
     * The name for the extension.
     */
    extensionName?: string;
    /**
     * The url to navigate to when the tour card is clicked.
     */
    url?: string;
    /**
     * The unique code identifier.
     */
    tourCode: string;
    /**
     * The number of days + the number of extension days.
     */
    nbDaysWithExtension?: number;
    /**
     * The number of cities in tour.
     */
    nbCities?: number;
};

export const SiteSearchCard: React.FC<SiteSearchCardProps> = (props) => {
    const { searchValue } = useSiteSearch();
    const {
        extensionName,
        cardImage,
        nbDays,
        nbDaysWithExtension,
        name,
        url,
        tourCode,
    } = props;
    const description = `${nbDays} ${pluralize("day", nbDays)}${
        nbDaysWithExtension
            ? " | " +
              nbDaysWithExtension +
              " " +
              pluralize("day", nbDaysWithExtension)
            : ""
    }${!!extensionName ? " with " + extensionName : ""}`;
    return (
        <Card
            href={url}
            className={styles.card}
            tag="a"
            onClickCapture={() => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        eventCategory: "header-search",
                        eventAction: "tour",
                        eventLabel: name,
                        ga4_object: "SEARCH_BAR",
                        ga4_action: "CLICKED",
                        resultType: "tour",
                        searchTerm: searchValue ?? "",
                        tourCode: tourCode,
                    },
                });
            }}
        >
            {cardImage && (
                <StoryblokImage
                    className={styles.media}
                    coverContainer
                    mobile={{
                        asset: cardImage,
                        size: { width: 320, height: 260 },
                    }}
                />
            )}
            <div
                style={{
                    padding: "var(--g-spacing-md)",
                }}
            >
                <Text.Body emphasis="2" tag="h4">
                    {name}
                </Text.Body>
                <Text.Body className={styles.description} title={description}>
                    {description}
                </Text.Body>
            </div>
        </Card>
    );
};
