import cn from "clsx";
import { FC, HtmlHTMLAttributes, PropsWithChildren } from "react";
import styles from "./Menu.module.scss";

export type MenuProps = PropsWithChildren &
    HtmlHTMLAttributes<HTMLElement> & {
        /**
         * Should hide menu item separators
         * @default false
         */
        hideBorders?: boolean;

        /**
         * Accessible title for the menu
         */
        accessibleTitle?: string;
    };

export const Menu: FC<MenuProps> = ({
    hideBorders,
    accessibleTitle,
    className,
    children,
    ...props
}) => {
    const menuItemsClassName = cn(
        styles["menu-items"],
        {
            [styles["menu-items--hide-borders"]]: hideBorders,
        },
        className,
    );

    return (
        <nav
            {...props}
            className={styles.menu}
            aria-label={accessibleTitle || undefined}
        >
            <ul className={menuItemsClassName}>{children}</ul>
        </nav>
    );
};
