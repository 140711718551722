import cn from "clsx";
import {
    Children,
    cloneElement,
    FC,
    PropsWithChildren,
    ReactElement,
} from "react";
import styles from "./Menu.module.scss";
import { MenuItemProps } from "./MenuItem";

export const MenuFooter: FC<PropsWithChildren> = ({ children }) => {
    const footerStyleClass = styles["menu-footer-item"];
    const items = Children.toArray(children);
    return (
        <>
            {items.map((item, i) =>
                cloneElement(item as ReactElement<MenuItemProps>, {
                    wrapperClassName: cn(
                        {
                            [styles["menu-footer-item-first"]]: i === 0,
                        },
                        footerStyleClass,
                    ),
                }),
            )}
        </>
    );
};
