import { gtmSendEvent } from "@wojo/analytics";
import { getCleanStoryblokLink, WojoNavGroup } from "@wojo/storyblok";
import { Button, Text } from "@wojo/ui";
import { NavDropdown } from "./NavDropdown";
import styles from "./NavItem.module.scss";

type NavItemProps = {
    navItem: WojoNavGroup;
};

export const NavItem: React.FC<NavItemProps> = ({ navItem }) => {
    const href = getCleanStoryblokLink(navItem.link).href;
    const hasColumns = !!navItem.columns?.length;
    return !hasColumns ? (
        <li>
            <Button
                tag="a"
                variant="quiet"
                href={href}
                className={styles.item}
                onClick={() => {
                    gtmSendEvent({
                        event: "track",
                        ga4Tracking: {
                            ga4_object: "NAV_BAR",
                            ga4_action: "CLICKED",
                            clickText: navItem.title ?? "",
                            sectionTier: "Tier 1",
                            destinationUrl: href,
                        },
                    });
                }}
            >
                <Text.Body tag="span" className={styles.title}>
                    {navItem.title}
                </Text.Body>
            </Button>
        </li>
    ) : (
        <NavDropdown navItem={navItem} />
    );
};
