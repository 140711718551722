import { getCleanStoryblokLink, ImageGraphicAtom } from "@wojo/storyblok";
import { Text } from "@wojo/ui";
import styles from "./AppBanner.module.scss";
import { WebConfigContentProps } from "./Header";

export const AppBanner: React.FC<WebConfigContentProps> = ({
    webConfigContent,
}) => {
    const iosApp = webConfigContent.iosApp.at(0);
    const androidApp = webConfigContent.androidApp.at(0);

    if (!iosApp || !androidApp) {
        return null;
    }

    const appStoreHref = getCleanStoryblokLink(iosApp.storeLink);
    const appStoreImageLightmode = iosApp.storeImage[0].lightModeImage;
    const appStoreImageDarkmode = iosApp.storeImage[0].darkModeImage;
    const appStoreImageSize = iosApp.storeImage[0].imageHeight;

    const playStoreHref = getCleanStoryblokLink(androidApp.storeLink);
    const playStoreImageLightmode = androidApp.storeImage[0].lightModeImage;
    const playStoreImageDarkmode = androidApp.storeImage[0].darkModeImage;
    const playStoreImageSize = androidApp.storeImage[0].imageHeight;

    if (
        !playStoreImageLightmode &&
        !playStoreImageDarkmode &&
        !appStoreImageLightmode &&
        !appStoreImageDarkmode
    ) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles["text-section"]}>
                <Text.Body emphasis="2">
                    The essential app for travelers
                </Text.Body>
                <Text.Body>Everything you need to plan, prep and go.</Text.Body>
            </div>
            <div className={styles["banner-section"]}>
                {!!appStoreImageLightmode && !!appStoreImageDarkmode && (
                    <a
                        className={styles["badge-link"]}
                        href={appStoreHref.href}
                    >
                        <ImageGraphicAtom
                            lightModeImage={appStoreImageLightmode}
                            darkModeImage={appStoreImageDarkmode}
                            imageHeight={appStoreImageSize}
                        />
                    </a>
                )}
                {!!playStoreImageLightmode && !!playStoreImageDarkmode && (
                    <a
                        className={styles["badge-link"]}
                        href={playStoreHref.href}
                    >
                        <ImageGraphicAtom
                            lightModeImage={playStoreImageLightmode}
                            darkModeImage={playStoreImageDarkmode}
                            imageHeight={playStoreImageSize}
                        />
                    </a>
                )}
            </div>
        </div>
    );
};
