import { useDismiss, useFloating, useInteractions } from "@floating-ui/react";
import { useMediaQuery } from "@mantine/hooks";
import { useI18n } from "@wojo/localization";
import { Button, Container, LoadingSpinner, SearchField, Text } from "@wojo/ui";
import { useEffect } from "react";
import { FiX } from "react-icons/fi";
import styles from "./SiteSearch.module.scss";
import { useSiteSearch } from "./SiteSearchContextProvider";
import { SiteSearchSectionBlog } from "./SiteSearchSectionBlog";
import { SiteSearchSectionPages } from "./SiteSearchSectionPages";
import { SiteSearchSectionTours } from "./SiteSearchSectionTours";

type SiteSearchProps = { placeholder?: string; className?: string };

export const SiteSearch: React.FC<SiteSearchProps> = ({
    placeholder = "Search",
    className,
}) => {
    const { isOpen, setIsOpen, indexSearch, onSearch, isLoading } =
        useSiteSearch();
    const headerLocale = useI18n("header");
    const { refs, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useDismiss(context),
    ]);
    const breakpointDesktop = "(min-width: 992px)";
    const isDesktop = useMediaQuery(breakpointDesktop, false);

    useEffect(() => {
        if (!isDesktop && isOpen) {
            setIsOpen(false);
        }
    }, [isDesktop, isOpen, setIsOpen]);

    return (
        <div className={className}>
            <SearchField
                onSearch={onSearch}
                onTypingStopped={indexSearch}
                placeholder={placeholder}
                variant="quiet"
                loading={isLoading}
                {...getReferenceProps()}
                onClear={() => setIsOpen(false)}
                onTextChange={(value) => {
                    !value && setIsOpen(false);
                }}
            />

            {isOpen && (
                <div
                    ref={refs.setFloating}
                    {...getFloatingProps()}
                    className={styles.dropdown}
                >
                    <Container>
                        <div
                            style={{
                                display: "grid",
                                rowGap: "var(--g-spacing-md)",
                                paddingBottom: "var(--g-spacing-md)",
                                marginBottom: "var(--g-spacing-md)",
                            }}
                        >
                            <Button
                                variant="quiet"
                                adornRight={<FiX aria-hidden size={20} />}
                                onClick={() => setIsOpen(false)}
                                style={{ justifySelf: "end" }}
                                type="button"
                            >
                                Close
                            </Button>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "grid",
                                        paddingTop: "var(--g-spacing-md",
                                        justifyContent: "center",
                                    }}
                                >
                                    <LoadingSpinner accessibleText="Loading search results" />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr 1fr",
                                        columnGap: "var(--g-spacing-xl)",
                                    }}
                                >
                                    <SiteSearchSectionPages>
                                        <Text.Detail variant="2">
                                            {headerLocale.header.siteSearch.suggestedTours.l()}
                                        </Text.Detail>
                                    </SiteSearchSectionPages>
                                    <SiteSearchSectionTours />
                                    <SiteSearchSectionBlog />
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            )}
        </div>
    );
};
