import { useAuth } from "@wojo/auth";
import { useI18n } from "@wojo/localization";
import { FiCompass, FiHeart, FiUser } from "react-icons/fi";
import { LuBook } from "react-icons/lu";

export const useAccountMenuData = () => {
    const accountNav = useI18n("global").global.accountNavigation;

    const savedQuotesEnabled =
        process.env.NEXT_PUBLIC_UNRELEASED_FEATURE_SAVED_QUOTES === "true";

    const { customer } = useAuth();
    const gcToolkitMenuItem =
        !!customer?.hasGCDepartures &&
        process.env.NEXT_PUBLIC_IS_GC_TOOLKIT_ENABLED === "true" // Despite it being possible for UB to have GC departures, only GAT has the GC Toolkit
            ? [
                  {
                      icon: <LuBook aria-hidden />,
                      title: accountNav.gcToolkit.text.l(),
                      href: accountNav.gcToolkit.url.l(),
                  },
              ]
            : [];

    const accountMenuLabel = accountNav.navigationLabel.l();

    const accountMenuData = [
        {
            title: accountNav.myTours.text.l(),
            href: accountNav.myTours.url.l(),
            icon: <FiCompass aria-hidden />,
        },
        {
            title: accountNav.profile.text.l(),
            href: accountNav.profile.url.l(),
            icon: <FiUser aria-hidden />,
        },
        {
            title: savedQuotesEnabled
                ? accountNav.saved.text.l()
                : accountNav.wishlist.text.l(),
            href: accountNav.saved.url.l(),
            icon: <FiHeart aria-hidden />,
        },
        ...gcToolkitMenuItem,
    ];

    return {
        accountMenuLabel,
        accountMenuData,
    };
};
