import { gtmSendEvent } from "@wojo/analytics";
import { AuthPaths, useAuth } from "@wojo/auth";
import { useI18n } from "@wojo/localization";
import { getCleanStoryblokLink } from "@wojo/storyblok";
import { Button, Text } from "@wojo/ui";
import { DesktopMyAccountMenu } from "../my-account-menu/DesktopMyAccountMenu";
import { WebConfigContentProps } from "./Header";
import { PhoneLink } from "./PhoneLink";
import { UtilityLink } from "./UtilityLink";
import styles from "./UtilityNav.module.scss";
import { WishlistButton } from "./WishlistButton";
import { usePhoneNumber } from "client/hooks";

export type UtilityNavLinkType = {
    text: string;
    href: string;
};

export const UtilityNav: React.FC<WebConfigContentProps> = ({
    webConfigContent,
}) => {
    const headerContent = webConfigContent.header;

    const { phoneNumber, formattedPhoneNumber } = usePhoneNumber();
    const { isLoggedIn, setView } = useAuth();
    const { header } = useI18n("header");
    const loginRegister = header.authentication.loginRegister.l();

    return (
        <nav aria-label="Quick links" className={styles["utility-nav"]}>
            <ul className={styles["utility-nav-list"]}>
                {headerContent.showPhoneOrWishlist === "phone" && (
                    <Text
                        tag="li"
                        key={phoneNumber}
                        className={styles["utility-list-item"]}
                    >
                        <PhoneLink
                            phoneNumber={phoneNumber}
                            formattedPhone={formattedPhoneNumber}
                        />
                    </Text>
                )}
                {headerContent.topLinks?.map((topLink) => {
                    const { href } = getCleanStoryblokLink(topLink.link);
                    return (
                        <Text
                            tag="li"
                            key={topLink.text}
                            className={styles["utility-list-item"]}
                        >
                            <UtilityLink
                                href={href}
                                onClick={() => {
                                    gtmSendEvent({
                                        event: "track",
                                        ga4Tracking: {
                                            ga4_object: "NAV_BAR",
                                            ga4_action: "CLICKED",
                                            clickText: topLink.text ?? "",
                                            sectionTier: "Tier 1",
                                            destinationUrl: href,
                                        },
                                    });
                                }}
                            >
                                {topLink.text}
                            </UtilityLink>
                        </Text>
                    );
                })}
                {headerContent.showPhoneOrWishlist === "wishlist" && (
                    <Text tag="li" className={styles["utility-list-item"]}>
                        <WishlistButton />
                    </Text>
                )}
                {isLoggedIn ? (
                    <DesktopMyAccountMenu />
                ) : (
                    <Text tag="li" className={styles["utility-list-item"]}>
                        <Button
                            variant="quiet"
                            size="small"
                            className={styles.login}
                            data-testid="login-button"
                            onClick={() => {
                                gtmSendEvent({
                                    event: "track",
                                    ga4Tracking: {
                                        ga4_object: "NAV_BAR",
                                        ga4_action: "CLICKED",
                                        clickText: loginRegister,
                                        sectionTier: "Tier 1",
                                        destinationUrl: "",
                                    },
                                });
                                setView?.({
                                    view: AuthPaths.VerifyEmail,
                                });
                            }}
                        >
                            {loginRegister}
                        </Button>
                    </Text>
                )}
            </ul>
        </nav>
    );
};
