import { gtmSendEvent } from "@wojo/analytics";
import { useI18n } from "@wojo/localization";
import { Button, Text } from "@wojo/ui";
import { FiBookOpen } from "react-icons/fi";
import styles from "./SiteSearch.module.scss";
import { useSiteSearch } from "./SiteSearchContextProvider";

export const SiteSearchSectionBlog: React.FC = () => {
    const { blogData, searchValue } = useSiteSearch();
    const headerLocale = useI18n("header");

    return (
        !!blogData.length && (
            <section>
                <Text.Detail variant="2">
                    {headerLocale.header.siteSearch.blogPosts.l()}
                </Text.Detail>
                {blogData.map((item) => (
                    <Text
                        href={`/${item.fullSlug}`}
                        key={item.objectID}
                        className={styles.suggested}
                        tag="a"
                        variant="2"
                        onClickCapture={() => {
                            gtmSendEvent({
                                event: "track",
                                ga4Tracking: {
                                    event: "track",
                                    eventCategory: "header-search",
                                    eventAction: "blog",
                                    eventLabel: item.fullSlug,
                                    ga4_object: "SEARCH_BAR",
                                    ga4_action: "CLICKED",
                                    resultType: "blog",
                                    searchTerm: searchValue ?? "",
                                    destinationUrl: item.fullSlug,
                                },
                            });
                        }}
                    >
                        <FiBookOpen
                            aria-hidden
                            style={{
                                alignSelf: "start",
                                paddingTop: "var(--g-spacing-xs)",
                            }}
                        />
                        <div style={{ display: "grid" }}>
                            <div
                                style={{
                                    fontWeight: "var(--g-font-weight-medium)",
                                }}
                            >
                                {item.title}
                            </div>
                            <div
                                style={{
                                    fontWeight: "var(--g-font-weight-regular)",
                                }}
                            >
                                {item.category}
                            </div>
                        </div>
                    </Text>
                ))}
                <Button
                    variant="quiet"
                    tag="a"
                    href={headerLocale.header.siteSearch.blogUrl.l()}
                    style={{
                        marginTop: "var(--g-spacing-md)",
                    }}
                    onClickCapture={() => {
                        gtmSendEvent({
                            event: "track",
                            ga4Tracking: {
                                eventCategory: "header-search",
                                eventAction: "blog",
                                eventLabel: "Visit the blog",
                                ga4_object: "SEARCH_BAR",
                                ga4_action: "CLICKED",
                                resultType: "blog",
                                searchTerm: searchValue ?? "",
                                destinationUrl:
                                    headerLocale.header.siteSearch.blogUrl.l(),
                            },
                        });
                    }}
                >
                    Visit the blog
                </Button>
            </section>
        )
    );
};
