"use client";
import { getCleanStoryblokLink, ImageGraphicAtom } from "@wojo/storyblok";
import { WebConfigContentProps } from "./Header";
import headerStyles from "./Header.module.scss";
import styles from "./MobileHeader.module.scss";
import { MobileMenu } from "./MobileMenu";
import { SiteSearchContextProvider, SiteSearchMobile } from "./site-search";
import { WishlistButton } from "./WishlistButton";
import { MobileHeaderPhone } from "./MobileHeaderPhone";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { LoadingSkeleton } from "@wojo/ui";
import { useI18n } from "@wojo/localization";

export const MobileHeader: React.FC<WebConfigContentProps> = ({
    webConfigContent,
}) => {
    const headerContent = webConfigContent.header;
    const { header } = useI18n("header");
    const { href } = getCleanStoryblokLink(headerContent.logoLink);

    return (
        <div className={styles.mobile}>
            <div className={headerStyles["main-nav-mobile"]}>
                {!!headerContent.logo[0] && (
                    <a href={href} className={headerStyles["logo-link"]}>
                        <ImageGraphicAtom
                            className={headerStyles.logo}
                            {...headerContent.logo[0]}
                        />
                    </a>
                )}

                <nav aria-label="Site" className={styles["menu-container"]}>
                    {headerContent.showPhoneOrWishlist === "phone" && (
                        <ErrorBoundary fallback={null}>
                            <Suspense
                                fallback={
                                    <LoadingSkeleton
                                        accessibleText={header.loadingPhone.l()}
                                        className={styles.loading}
                                    />
                                }
                            >
                                <MobileHeaderPhone />
                            </Suspense>
                        </ErrorBoundary>
                    )}
                    {headerContent.showPhoneOrWishlist === "wishlist" && (
                        <WishlistButton />
                    )}
                    <ErrorBoundary fallback={null}>
                        <Suspense
                            fallback={
                                <LoadingSkeleton
                                    height="24px"
                                    width="70px"
                                    accessibleText={header.loadingMenu.l()}
                                />
                            }
                        >
                            <MobileMenu webConfigContent={webConfigContent} />
                        </Suspense>
                    </ErrorBoundary>
                </nav>
            </div>
            <SiteSearchContextProvider>
                <SiteSearchMobile
                    placeholder={headerContent.searchPlaceholder}
                />
            </SiteSearchContextProvider>
        </div>
    );
};
