"use client";

import {
    FloatingFocusManager,
    useDismiss,
    useFloating,
    useInteractions,
} from "@floating-ui/react";
import { gtmSendEvent } from "@wojo/analytics";
import { useAuth } from "@wojo/auth";
import { useI18n } from "@wojo/localization";
import {
    Avatar,
    Menu,
    MenuFooter,
    MenuItem,
    Text,
    transformAsset,
} from "@wojo/ui";
import { ZendeskHelpCenterLink } from "@wojo/web-components";
import cn from "clsx";
import { useState } from "react";
import { FiChevronDown, FiExternalLink } from "react-icons/fi";
import { UtilityButton } from "../header/UtilityButton";
import utilityNavStyles from "../header/UtilityNav.module.scss";
import myAccountStyles from "./DesktopMyAccountMenu.module.scss";
import { useAccountMenuData } from "./hooks/use-account-menu-data";

export const DesktopMyAccountMenu: React.FC = () => {
    const { accountMenuLabel, accountMenuData } = useAccountMenuData();
    const { signout, customer } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const { context, refs } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
    });
    const { getReferenceProps } = useInteractions([
        useDismiss(context, { outsidePressEvent: "click" }),
    ]);
    const { header } = useI18n("header");
    const { global } = useI18n("global");
    const signOut = header.authentication.signOut.l();
    const avatarSrc = customer?.profileImageAsset?.location ?? "";

    return (
        <>
            <Text
                tag="li"
                className={cn(
                    utilityNavStyles["utility-list-item"],
                    myAccountStyles["my-account-menu-item"],
                )}
            >
                <UtilityButton
                    ref={refs.setReference}
                    {...getReferenceProps({
                        onClick: () => {
                            setIsOpen(!isOpen);
                            gtmSendEvent({
                                event: "track",
                                ga4Tracking: {
                                    ga4_object: "NAV_BAR",
                                    ga4_action: "CLICKED",
                                    clickText: accountMenuLabel,
                                    sectionTier: "Tier 1",
                                    destinationUrl: "",
                                },
                            });
                        },
                        "aria-expanded": isOpen,
                    })}
                    className={myAccountStyles.wrapper}
                >
                    <span style={{ textDecoration: "none" }}>
                        <Avatar
                            size={26}
                            altText={`${customer?.firstName} ${customer?.lastName}`.trim()}
                            initials={
                                customer?.firstName
                                    ? `${customer.firstName.charAt(
                                          0,
                                      )}${customer.lastName?.charAt(0)}`
                                    : ""
                            }
                            src={transformAsset(avatarSrc, {
                                size: {
                                    width: 26,
                                    height: 26,
                                },
                            })}
                        />
                    </span>
                    <span
                        style={{
                            marginLeft: "var(--g-spacing-sm)",
                            marginRight: "var(--g-spacing-xs)",
                        }}
                    >
                        {accountMenuLabel}
                    </span>
                    <FiChevronDown size={16} aria-hidden={true} />
                </UtilityButton>
                {isOpen && (
                    <FloatingFocusManager context={context} modal={false}>
                        <div
                            ref={refs.setFloating}
                            className={myAccountStyles["floating-menu"]}
                        >
                            <Menu
                                accessibleTitle={`${accountMenuLabel} menu`}
                                className={myAccountStyles["menu"]}
                            >
                                {accountMenuData.map((item, index) => (
                                    <MenuItem
                                        key={`${item.title}${index}`}
                                        adornLeft={item.icon}
                                        href={item.href}
                                        tag="a"
                                        data-lemons={item.title}
                                    >
                                        {item.title}
                                    </MenuItem>
                                ))}
                                {process.env
                                    .NEXT_PUBLIC_ZENDESK_HELP_CENTER_URL && (
                                    <MenuItem tag="span">
                                        <ZendeskHelpCenterLink
                                            adornLeft={
                                                <FiExternalLink aria-hidden="true" />
                                            }
                                            className={
                                                myAccountStyles["zendesk-link"]
                                            }
                                        >
                                            {global.accountNavigation.helpCenter.text.l()}
                                        </ZendeskHelpCenterLink>
                                    </MenuItem>
                                )}
                                <MenuFooter>
                                    <MenuItem
                                        onClick={async () => {
                                            await signout?.();
                                            gtmSendEvent({
                                                event: "track",
                                                ga4Tracking: {
                                                    eventCategory: "login",
                                                    eventAction:
                                                        "header register now",
                                                    eventLabel: "logout",
                                                    ga4_object: "LOGIN",
                                                    ga4_action: "LOGGED_OUT",
                                                    loginId:
                                                        "headerPasswordAccount",
                                                    magicLink: "no",
                                                },
                                            });
                                        }}
                                    >
                                        {signOut}
                                    </MenuItem>
                                </MenuFooter>
                            </Menu>
                        </div>
                    </FloatingFocusManager>
                )}
            </Text>
        </>
    );
};
