"use client";

import cn from "clsx";
import { ChangeEvent, forwardRef, useState } from "react";
import { FiSearch, FiXCircle } from "react-icons/fi";
import { useDebouncedCallback } from "use-debounce";
import { LoadingSpinner } from "../loading-spinner";
import { Textbox, TextboxProps } from "../text-fields/textbox";
import styles from "./SearchField.module.scss";

export type SearchFieldProps = Omit<TextboxProps, "hasError"> & {
    /**
     * Determines the search field style
     * @default standard
     */
    variant?: "standard" | "quiet";
    /**
     * Inital search value
     */
    initialValue?: string;
    /**
     * Callback function when a search is performed
     * @param value
     */
    onSearch: (value: string) => Promise<void>;
    /**
     * Callback function when the search field is cleared
     */
    onClear?: () => void;
    /**
     * Callback function when the user stops typing
     */
    onTypingStopped?: (value: string) => Promise<void>;
    /**
     * Callback function when the user starts typing
     */
    onTypingForwardChange?: (isTyping: boolean) => void;
    /**
     * Callback function when the search term is changed
     */
    onTextChange?: (value: string) => void;
};

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
    (
        {
            variant = "standard",
            initialValue = "",
            adornRight = (
                <FiSearch
                    title="Submit Search"
                    aria-label="Submit Search"
                    role="img"
                />
            ),
            adornLeft,
            disabled,
            className,
            onSearch,
            placeholder,
            onTypingForwardChange,
            onTypingStopped,
            loading,
            onClear,
            onTextChange,
            wrapperClassName,
            ...searchFieldProps
        },
        ref,
    ) => {
        const [value, setValue] = useState<string>(initialValue);

        const searchFieldClassName = cn(className, styles["search-field"], {
            [styles[`search-field--${variant}`]]: variant,
        });
        const searchFieldWrapperClassName = cn(
            styles["search-field-wrapper"],
            {
                [styles[`search-field-wrapper--${variant}`]]: variant,
                [`search-field-wrapper-${variant}-palette`]: variant,
                [styles[`search-field-wrapper--${variant}--disabled`]]:
                    disabled,
            },
            wrapperClassName,
        );
        const adornRightClassName = cn(styles["adorn-right"], {
            [styles[`adorn-right--${variant}`]]: variant,
            [styles[`adorn-right--disabled`]]: disabled,
            [styles[`adorn-right--${variant}--disabled`]]: disabled && variant,
        });
        const adornLeftClassName = cn(styles["adorn-left"], {
            [styles[`adorn-left--disabled`]]: disabled,
        });

        const onDebouncedTypingStopped = useDebouncedCallback(
            async (value: string) => {
                await onTypingStopped?.(value);
            },
            300,
        );

        const handleTypingForwardChange = (isTyping: boolean) => {
            onTypingForwardChange?.(isTyping);
        };

        const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.currentTarget.value;
            setValue(newValue);
            onTextChange?.(newValue);
            handleTypingForwardChange(newValue.length > value.length);
            onDebouncedTypingStopped(newValue);
        };

        const handleClear = () => {
            if (!loading && !disabled) {
                setValue("");
                onClear?.();
                typeof ref === "object" && ref?.current?.focus();
            }
        };

        const handleSearch = async () => {
            if (!loading && !disabled) {
                await onSearch(value.trim());
            }
        };

        const right = (
            <div className={adornRightClassName}>
                {value && (
                    <>
                        <button
                            onClick={handleClear}
                            className={styles["clear-button"]}
                            aria-disabled={disabled || loading || undefined}
                            type="button"
                        >
                            <FiXCircle
                                aria-label="Clear search"
                                title="Clear search"
                                role="img"
                            />
                        </button>
                        <span className={styles["vertical-separator"]} />
                    </>
                )}

                <button
                    className={styles["search-button"]}
                    aria-disabled={disabled || loading || undefined}
                    onClick={async () => await handleSearch()}
                >
                    {loading ? (
                        <LoadingSpinner accessibleText="Loading results" />
                    ) : (
                        adornRight
                    )}
                </button>
            </div>
        );

        const left = adornLeft && (
            <span className={adornLeftClassName}>{adornLeft}</span>
        );

        return (
            <Textbox
                {...searchFieldProps}
                aria-label={placeholder || undefined}
                type="search"
                ref={ref}
                wrapperClassName={searchFieldWrapperClassName}
                className={searchFieldClassName}
                adornLeft={left}
                adornRight={right}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onClick={(e) => {
                    searchFieldProps.onClick?.(e);
                    value.length &&
                        typeof ref === "object" &&
                        ref?.current?.select();
                }}
                onChange={handleTextChange}
                onKeyDown={async ({ key }) => {
                    if (key === "Enter") {
                        await handleSearch();
                    }
                }}
            />
        );
    },
);

SearchField.displayName = "SearchField";
