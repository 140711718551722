import { gtmSendEvent } from "@wojo/analytics";
import { Text } from "@wojo/ui";
import { PropsWithChildren } from "react";
import { FiSearch } from "react-icons/fi";
import styles from "./SiteSearch.module.scss";
import { useSiteSearch } from "./SiteSearchContextProvider";

export const SiteSearchSectionPages: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const { pagesData, searchValue } = useSiteSearch();

    return (
        !!pagesData.length && (
            <div>
                {children}
                {pagesData.map((item) => (
                    <Text
                        href={item.fullSlug}
                        key={item.objectID}
                        className={styles.suggested}
                        tag="a"
                        variant="2"
                        onClickCapture={() => {
                            gtmSendEvent({
                                event: "track",
                                ga4Tracking: {
                                    event: "track",
                                    eventCategory: "header-search",
                                    eventAction: "suggested search",
                                    eventLabel: item.title,
                                    ga4_object: "SEARCH_BAR",
                                    ga4_action: "CLICKED",
                                    resultType: "destination",
                                    searchTerm: searchValue ?? "",
                                },
                            });
                        }}
                    >
                        <FiSearch aria-hidden="true" />
                        <span
                            style={{
                                fontWeight: "var(--g-font-weight-regular)",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: item._highlightResult.siteSearchKeyword
                                    .value,
                            }}
                        />
                    </Text>
                ))}
            </div>
        )
    );
};
