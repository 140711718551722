"use client";

import { getCleanStoryblokLink, ImageGraphicAtom } from "@wojo/storyblok";
import cn from "clsx";
import { DesktopMegamenu } from "components/megamenu";
import styles from "./DesktopHeader.module.scss";
import { WebConfigContentProps } from "./Header";
import headerStyles from "./Header.module.scss";
import { SiteSearch, SiteSearchContextProvider } from "./site-search";
import { UtilityNav } from "./UtilityNav";
import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
import { LoadingSkeleton } from "@wojo/ui";
import { useI18n } from "@wojo/localization";

export const DesktopHeader: React.FC<WebConfigContentProps> = ({
    webConfigContent,
}) => {
    const headerContent = webConfigContent.header;
    const { header } = useI18n("header");
    const { href } = getCleanStoryblokLink(headerContent.logoLink);

    return (
        <div className={cn(styles.desktop, headerStyles["main-nav"])}>
            {!!headerContent.logo[0] && (
                <a href={href} className={headerStyles["logo-link"]}>
                    <ImageGraphicAtom
                        className={headerStyles.logo}
                        {...headerContent.logo[0]}
                    />
                </a>
            )}
            <div className={styles["nav-wrapper"]}>
                <ErrorBoundary fallback={null}>
                    <Suspense
                        fallback={
                            <LoadingSkeleton
                                width="503px"
                                height="24px"
                                accessibleText={header.loadingUtility.l()}
                                style={{
                                    justifySelf: "flex-end",
                                    marginBottom: "var(--g-spacing-sm)",
                                    display: "flex",
                                }}
                            />
                        }
                    >
                        <UtilityNav webConfigContent={webConfigContent} />
                    </Suspense>
                </ErrorBoundary>
                <div className={styles["nav-bottom"]}>
                    <DesktopMegamenu
                        className={styles["desktop-megamenu"]}
                        webConfigContent={webConfigContent}
                    />
                    <div className={styles.search}>
                        <SiteSearchContextProvider>
                            <SiteSearch
                                placeholder={headerContent.searchPlaceholder}
                            />
                        </SiteSearchContextProvider>
                    </div>
                </div>
            </div>
        </div>
    );
};
