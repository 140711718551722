import { gtmSendEvent } from "@wojo/analytics";
import cn from "clsx";
import { FiPhone } from "react-icons/fi";
import styles from "./PhoneAndWishlistButton.module.scss";
import { UtilityLink } from "./UtilityLink";

export type PhoneLinkProps = {
    phoneNumber: string;
    formattedPhone: string;
    isModalVariant?: boolean;
};

export const PhoneLink: React.FC<PhoneLinkProps> = ({
    phoneNumber,
    formattedPhone,
    isModalVariant = false,
}) => {
    return (
        <UtilityLink
            href={`tel:${phoneNumber}`}
            isModalVariant={isModalVariant}
            onClick={() => {
                gtmSendEvent({
                    event: "track",
                    ga4Tracking: {
                        ga4_object: "NAV_BAR",
                        ga4_action: "PHONE_CALL",
                        phoneNumber: phoneNumber,
                    },
                });
            }}
        >
            <span className="visually-hidden">Call us:</span>
            <span className={styles.wrapper}>
                <span
                    className={cn(styles["text"], {
                        [styles["text--modal"]]: isModalVariant,
                    })}
                >
                    {formattedPhone}
                </span>
                <FiPhone
                    className={cn(styles["icon"], {
                        [styles["icon--modal"]]: isModalVariant,
                    })}
                    aria-hidden
                />
            </span>
        </UtilityLink>
    );
};
